



































































































































































































































































































































































import { Vue, Component, Watch, Emit, Prop, Inject } from "vue-property-decorator";
import { ItemWrapGrid, ContextMenu, FileDropAreaCompact, ConfirmDialog, ConfirmDialogContent } from "ui-gallery";
import { Document } from "@/models/documents/Document";
import { DateTime } from "luxon";
import { DocumentPage } from "../../../../models/documents/DocumentPage";
import { AuthService } from "@/models/auth/AuthService";
import { container } from "tsyringe";
import { config } from "@/config";

/**
 * 資料を一覧表示するためのコントロールを提供します.
 */
@Component({ components: { ConfirmDialog, FileDropAreaCompact, ItemWrapGrid, ContextMenu } })
export default class DocumentListView extends Vue {
    private isUploadMenuOpened: boolean | null = null;
    private isCreateNewGroupMenuOpened: boolean | null = null;
    private isShowViewer = false;
    currentFolder: any[] = [];
    open = ["public"];
    id = 4;
    contents = [];
    private newGroupName = "";
    private documentContextMenuItem: Document<DocumentPage> | null = null;
    private groupContextMenuItem: any | null = null;
    private file: File | null = null;
    private uploadGroup: string | null = "";
    private isSetPassword = false;
    private config = config;

    private readonly authService = container.resolve(AuthService);

    get token() {
        return this.authService.token;
    }

    @Prop({ default: {} }) readonly value!: any;

    /**
     * 資料が削除リクエストされたときに発火します．
     * @param docId 削除する資料ID
     */
    @Emit("removeDocumentRequested")
    private removeDocumentRequested(docId: string) { }

    /**
     * ファイルがアップロードリクエストされたときに発火します．
     * @param file アップロードするファイル
     */
    @Emit("uploadRequested")
    private uploadRequested(file: File, dgId: string, isSetPassword: boolean) { }

    /**
     * 資料編集リクエストされたときに発火します．
     * @param doc 編集する資料
     */
    @Emit("editRequested")
    private editRequested(doc: Document<DocumentPage>) { }

    /**
     * 資料が選択されたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("documentSelected")
    private documentSelected(doc: Document<DocumentPage>) { }

    /**
     * フォルダの新規作成リクエストされたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("createNewGroupRequested")
    private createNewGroupRequested(name: string) { }

    /**
     * フォルダの名前変更リクエストされたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("renameGroupRequested")
    private renameGroupRequested(dgId: string, name: string) { }

    /**
     * 資料の名前変更リクエストされたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("renameDocumentRequested")
    private renameDocumentRequested(docId: string, name: string) { }

    /**
     * フォルダの新規作成リクエストされたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("removeGroupRequested")
    private removeGroupRequested(dgId: string) { }

    /**
     * 資料の公開リクエストされたときに発火します．
     * @param doc 選択された資料
     */
    @Emit("changePasswordRequested")
    private changePasswordRequested(docId: string) { }

    @Emit("reloadRequested")
    private reloadRequested() { }

    /**
     * 資料一覧
     */
    @Prop({ default: [] }) readonly documents!: Document<DocumentPage>[];

    /**
     * 資料一覧
     */
    @Prop({ default: false }) readonly isLoading!: boolean;

    /**
     * グループを開いた時の処理
     */
    @Prop({ default: Function }) readonly loadChildren!: (item: {
        name: string;
        children?: any[];
        file?: string;
    }) => Promise<void>;

    /**
     * フォルダ一覧
     */
    @Prop({ default: [] }) readonly documentGroups!: any[];

    /**
     * アップロードするグループに初期値をセットします．
     */
    @Watch("documentGroups")
    private onDocumentGroupsChanged() {
        if (this.documentGroups.length > 0 && (!this.value || !this.documentGroups.indexOf(this.value))) {
            this.uploadGroup = this.documentGroups[0].dgId;
            this.$emit("input", this.documentGroups[0]);
        }
    }

    @Inject()
    private input!: (message: string, initialValue: string) => Promise<string>;

    /**
     * DOMが生成されたときに実行されます．
     */
    private mounted() {
        this.onDocumentGroupsChanged();
    }

    /**
     * フォルダの新規作成ボタンを押したときに実行されます．
     */
    private onCreateNewGroupClicked() {
        this.createNewGroupRequested(this.newGroupName);
        this.isCreateNewGroupMenuOpened = false;
    }

    /**
     * グループを選択してアクティブにします．
     */
    private onGroupSelected(item: any) {
        this.uploadGroup = item.dgId;
        this.$emit("input", item);
    }

    /**
     * 日付をフォーマットします．
     */
    private getDate(date: string) {
        return DateTime.fromISO(date).toFormat("yyyy年MM月dd日");
    }

    /**
     * ファイルピッカーからファイルを受け取ったとき．
     */
    private onRecieved(file: File) {
        this.file = file;
    }

    /**
     * アップロードボタンがクリックされたときに実行されます．
     */
    private async onUploadClicked(): Promise<void> {
        this.isUploadMenuOpened = false;
        if (this.file && this.uploadGroup) {
            this.uploadRequested(this.file, this.uploadGroup, this.isSetPassword);
        }
    }

    /**
     * ファイルアップロードメニューが開いたり閉じたりしたときに実行されます．
     */
    @Watch("isUploadMenuOpened")
    private onIsUploadMenuOpenedChanged() {
        this.file = null;
        this.isSetPassword = false;
    }

    /**
     * ファイルアップロードメニューが開いたり閉じたりしたときに実行されます．
     */
    @Watch("isCreateNewGroupMenuOpened")
    private onIsCreateNewGroupMenuOpened() {
        this.newGroupName = "";
    }

    /**
     * 資料のサムネイルをクリックしたときに実行されます．
     */
    private onDocumentSelected(doc: Document<DocumentPage>) {
        this.documentSelected(doc);
    }

    /**
     * コンテキストメニューを表示します．
     * @param e マウスイベント情報
     * @param doc クリックした資料
     */
    private onShowDocumentContextMenu(e: MouseEvent, doc: Document<DocumentPage>) {
        this.documentContextMenuItem = doc;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            menu.show(e.x - 240, e.y - 40);
        }
    }

    /**
     * グループのコンテキストメニューを表示します．
     * @param e マウスイベント情報
     * @param doc クリックした資料
     */
    private onShowGroupContextMenu(e: MouseEvent, group: any) {
        this.groupContextMenuItem = group;
        const groupMenu = this.$refs.groupMenu as ContextMenu | undefined;
        if (groupMenu) {
            groupMenu.show(e.x - 240, e.y - 40);
        }
    }

    /**
     * グループの削除イベントを発火させます．
     */
    private async onRemoveGroup(): Promise<void> {
        this.closeContextMenu();
        const dialog = this.$refs.confirmDialog as any | undefined;
        if (!(await dialog.showAsync(new ConfirmDialogContent({
            title: `フォルダ ${this.groupContextMenuItem.name} と含まれる資料を全て削除しますか？`
        })))) return;

        if (this.groupContextMenuItem) {
            this.removeGroupRequested(this.groupContextMenuItem.dgId);
        }
    }

    /**
     * グループの名前変更イベントを発火させます．
     */
    private async onRenameGroup(): Promise<void> {
        this.closeContextMenu();
        if (this.groupContextMenuItem) {
            const name = await this.input("新しいフォルダ名を入力してください", this.groupContextMenuItem.name);
            if (name) {
                this.renameGroupRequested(this.groupContextMenuItem.dgId, name);
            }
        }
    }

    /**
     * 資料のの名前変更イベントを発火させます．
     */
    private async onRenameDocument(): Promise<void> {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            const name = await this.input("新しい資料名を入力してください", this.documentContextMenuItem.name);
            if (name) {
                this.renameDocumentRequested(this.documentContextMenuItem.docId, name);
            }
        }
    }

    /**
     * 資料をプレビューします．
     */
    private onPreview() {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            this.documentSelected(this.documentContextMenuItem);
        }
    }

    /**
     * パスワードを変更します
     */
    private async onChangePassword() {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            this.changePasswordRequested(this.documentContextMenuItem.docId);
        }
    }

    private async download() {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            window.open(config.marlboroUrl + "/download/docs/" + this.documentContextMenuItem.docId);
        }
    }

    /**
     * コンテキストメニューの編集をクリックしたときに実行されます．
     */
    private edit() {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            this.editRequested(this.documentContextMenuItem);
        }
    }

    /**
     * 資料を削除します．
     */
    private async onRemoveDocument(): Promise<void> {
        this.closeContextMenu();
        const dialog = this.$refs.confirmDialog as any | undefined;

        if (this.documentContextMenuItem && dialog) {
            const isRemove = await dialog.showAsync(new ConfirmDialogContent({
                title: `資料 ${this.documentContextMenuItem.name} を削除しますか？`,
                cancelText: "キャンセル",
                okText: "削除する"
            }));
            if (!isRemove) return;
            this.removeDocumentRequested(this.documentContextMenuItem.docId);
        }
    }

    /**
     * コンテキストメニューを閉じます．
     */
    private closeContextMenu() {
        const groupMenu = this.$refs.groupMenu as ContextMenu | undefined;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu && groupMenu) {
            menu.close();
            groupMenu.close();
        }
    }
}
